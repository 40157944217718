.slick-prev:before,
.slick-next:before {
    color: rgba(0, 0, 0, 0.5);
    font-size: 30px;
}

.slick-prev,
.slick-next {
    width: 30px;
    height: 30px;
}

.slick-next {
    right: -35px
}

.slick-prev {
    left: -35px
}