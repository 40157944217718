* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  opacity: 0.5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: rgba(175, 173, 173, 0.575);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(175, 173, 173, 1);
}

#root {
  width: 100%;
  height: 100%;
}

input {
  width: 100%;
  height: 100%;
}

.App {
  /* text-align: center; */
}

button {
  background-color: unset;
  border: none;
  outline: none;
}

.App {
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.clock-touch-press {
  color: #000;
  border: none;
}

.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

.selected-view .ant-collapse-content-box {
  padding: 0 !important;
}

.toolbox-item:hover {
  background-color: #80c9ff !important;
}

.selected-view-row {
  height: 40px;
}

.selected-view-cell {
  padding: 0px 8px !important;
}